import React, { useEffect, useMemo, useRef, useState } from "react";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  PolygonF,
} from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import { isUndefined, values } from "lodash";
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import * as Locale from "date-fns/locale";
import moment from "moment";
import { useParams } from "react-router";
import { v4 as uuidv4 } from "uuid";
import AutoSizer from "react-virtualized-auto-sizer";
import env from "react-dotenv";
import { useTranslation } from "react-i18next";

import { useWindowSize } from "../../customHook/customHooks";

import { setDefaultValue } from "../../slices/loadingSlice";
import { fetchLocation } from "../../slices/locationSlice";
import { fetchSafeZoneUser } from "../../slices/safeZoneUserSlice";

import { InitialReducer } from "../../interfaces/reducer.interface";
import { Location } from "../../interfaces/location.interface";
import { Polygon, SafeZone } from "../../interfaces/safeZone.interface";

import { StatusDevice } from "../../enums/statusEnum";

import navLeft from "../../static/angles-right-solid.svg";
import markerImg from "../../static/marker.svg";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./Map.scss";

const Map = () => {
  const { device } = useParams();
  const [size, height] = useWindowSize();
  const [map, setMap] = useState<any>();
  const [isOpen, setIsOpen] = useState(true);
  const [deviceId, setDeviceId] = useState(device);
  const [markerActive, setMarkerActive] = useState("");
  const [isPickRange, setIsPickRange] = useState(false);
  const [status, setStatus] = useState(999);
  const [page, setPage] = useState(0);
  const [position, setPosition] = useState({
    lat: 35.678975150911555,
    lng: 139.76323706941017,
  });
  const [date, setDate] = useState<any>([
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const userItem = useSelector((state: InitialReducer) => state.userItem);
  const location = useSelector((state: InitialReducer) => state.location);
  const safeZoneUser = useSelector(
    (state: InitialReducer) => state.safeZoneUser
  );

  const containerStyle = {
    width: "100%",
    height: size > 426 ? "calc(100vh - 140px)" : "calc(100vh - 400px)",
  };

  //@ts-ignore
  const markerImage = new google.maps.MarkerImage(
    markerImg,
    null /* size is determined at runtime */,
    null /* origin is 0,0 */,
    null /* anchor is bottom center of the scaled image */,
    new window.google.maps.Size(32, 32)
  );

  useEffect(() => {
    dispatch(setDefaultValue({ isLoading: true }));

    if (userItem.role === "User") {
      dispatch(fetchSafeZoneUser({ deviceGUID: device }));
    }
  }, []);

  useEffect(() => {
    setMarkerActive("");
    dispatch(
      fetchLocation({
        UserGUID: userItem.guid,
        DeviceGUID: deviceId,
        DateStart: new Date(date[0].startDate),
        DateEnd: new Date(date[0].endDate),
        AlertID: status,
        PageNum: page,
        PageSize: env.PAGE_SIZE_SCROLL,
        currentRequestId: null,
      })
    );
  }, [status, date, page, dispatch]);

  const handleMarker = (guid: string) => {
    setMarkerActive(guid);
  };

  const handleDrawPolygon = (
    polygon: Array<Polygon>
  ): Array<{ lat: number; lng: number }> => {
    const item = new Array<{ lat: number; lng: number }>();
    polygon.forEach((s: Polygon) => {
      item.push({ lat: s.latitude, lng: s.longitude });
    });

    return item;
  };

  const handleScroll = (e: any) => {
    const scrollTop = e.target.scrollTop;
    const clientHeight = e.target.clientHeight;
    const scrollHeight = e.target.scrollHeight;
    let total = scrollTop + clientHeight;

    if (scrollHeight - total <= 1 && scrollHeight - total >= 0) {
      if (location.entities.length === location.total) {
        return;
      }

      let pageIncrease = page + 1;
      setPage(pageIncrease);
    }
  };

  const handleStatus = (labelId: number): string => {
    let obj = values(StatusDevice).find((s) => s.Value === labelId);
    if (!isUndefined(obj)) {
      return obj.Text;
    }

    return "";
  };

  /* handle open - close DateRange */
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (count == 2) {
      setIsPickRange(false);
      setCount(0);
    }
  }, [count]);

  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        console.log("click outside");
        setIsPickRange(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  /* end of handle open - close DateRange */

  return (
    <div className="container">
      <GoogleMap
        options={{
          draggableCursor: "default",
          draggingCursor: "pointer",
          fullscreenControl: false,
        }}
        center={position}
        zoom={15}
        mapContainerStyle={containerStyle}
        onLoad={(item) => setMap(item)}
      >
        {location.entities.length > 0 &&
          location.entities.map((m: Location) => (
            <MarkerF
              icon={markerImage}
              key={m.guid}
              position={{ lat: m.latitude, lng: m.longitude }}
              onClick={(event) => handleMarker(m.guid)}
            >
              {markerActive === m.guid ? (
                <InfoWindowF onCloseClick={() => setMarkerActive("")}>
                  <div className="marker-info">
                    <ul>
                      <li>
                        <label>{t("lbStatus")}: </label>
                        <h3>{t(handleStatus(m.alertID))}</h3>
                      </li>
                      <li>
                        <label>{t("lbTime")}: </label>
                        <h3>
                          {moment(m.dateCreated).format(t("txtDateTimeFormat"))}
                        </h3>
                      </li>
                      <li>
                        <label>{t("lbAddress")}: </label>
                        <h3>{m.address}</h3>
                      </li>
                      <li>
                        <label>{t("lbBattery")}: </label>
                        <h3>{m.battery}</h3>
                      </li>
                    </ul>
                  </div>
                </InfoWindowF>
              ) : null}
            </MarkerF>
          ))}

        {safeZoneUser.entities &&
          safeZoneUser.entities.length > 0 &&
          safeZoneUser.entities.map((s: SafeZone) => (
            <PolygonF
              key={s.polygonID}
              options={{
                draggable: false,
                fillColor: "#eeb557",
                fillOpacity: 0.2,
                strokeWeight: 2,
                strokeColor: "#eeb557",
                editable: false,
                zIndex: 1,
                geodesic: true,
              }}
              path={handleDrawPolygon(s.polygon)}
            ></PolygonF>
          ))}
      </GoogleMap>

      {isPickRange ? (
        <div ref={containerRef} className="date-view">
          <DateRange
            rangeColors={["#eeb557"]}
            locale={Locale.ja}
            ranges={date}
            onChange={(item) => {
              setDate([item["selection"]]);
              setCount(count + 1);
            }}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            retainEndDateOnFirstSelection={false}
          />
        </div>
      ) : null}

      {size >= 768 ? (
        <>
          <div className={`container-list-item ${!isOpen ? "closed" : ""}`}>
            <div className="container-list-item-search">
              <div className="container-list-item-search-date">
                {isOpen ? (
                  <>
                    <label>{t("lbDateRange")}</label>
                    <div
                      className="container-list-item-search-date-pick"
                      onClick={() => setIsPickRange(!isPickRange)}
                    >
                      {`${moment(date[0].startDate).format(
                        t("txtDateFormat")
                      )} ~ ${moment(date[0].endDate).format(
                        t("txtDateFormat")
                      )}`}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="container-list-item-search-status">
                {isOpen ? (
                  <>
                    <label>{t("lbDeviceStatus")}</label>
                    <select
                      className="container-list-item-search-status-select"
                      onChange={(e) => {
                        setStatus(parseInt(e.target.value));
                      }}
                      value={status}
                    >
                      {values(StatusDevice).map((s) => (
                        <option key={uuidv4()} value={s.Value}>
                          {t(s.Text)}
                        </option>
                      ))}
                    </select>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div
              className="container-list-item-detail"
              onScroll={(e) => handleScroll(e)}
            >
              <ul>
                {location.entities.length > 0 &&
                  location.entities.map((s: Location) => (
                    <li key={s.guid}>
                      <div
                        className="item-child"
                        style={{}}
                        onClick={() => {
                          setPosition({ lat: s.latitude, lng: s.longitude });
                          setMarkerActive(s.guid);
                        }}
                      >
                        <i
                          className="material-icons"
                          style={{ fontSize: "24px" }}
                        >
                          my_location
                        </i>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <h5>
                            {t("lbInfo")}: {s.address}{" "}
                          </h5>
                          <h3>
                            {t("lbTime")}:{" "}
                            {moment(s.dateCreated).format("HH:mm")}
                          </h3>
                          <h3>
                            {t("lbDate")}:{" "}
                            {moment(s.dateCreated).format(
                              `${t("txtDateFormat")}`
                            )}
                          </h3>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <button
            className={`button-close ${!isOpen ? "active" : ""}`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <img src={navLeft} width={15} height={15} />
          </button>
        </>
      ) : (
        <>
          <div className="map-mobile">
            <div className="container-list-item-search">
              <div className="container-list-item-search-date">
                {isOpen ? (
                  <>
                    <label>{t("lbDateRange")}</label>
                    <div
                      className="container-list-item-search-date-pick"
                      onClick={() => setIsPickRange(!isPickRange)}
                    >
                      {`${moment(date[0].startDate).format(
                        t("txtDateFormat")
                      )} ~ ${moment(date[0].endDate).format(
                        t("txtDateFormat")
                      )}`}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="container-list-item-search-status">
                {isOpen ? (
                  <>
                    <label>{t("lbDeviceStatus")}</label>
                    <select
                      className="container-list-item-search-status-select"
                      onChange={(e) => {
                        setStatus(parseInt(e.target.value));
                      }}
                      value={status}
                    >
                      {values(StatusDevice).map((s) => (
                        <option key={uuidv4()} value={s.Value}>
                          {t(s.Text)}
                        </option>
                      ))}
                    </select>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div
              className="container-list-item-detail"
              onScroll={(e) => handleScroll(e)}
            >
              <ul>
                {location.entities.length > 0 &&
                  location.entities.map((s: Location) => (
                    <li key={s.guid}>
                      <div
                        className="item-child"
                        style={{}}
                        onClick={() => {
                          setPosition({ lat: s.latitude, lng: s.longitude });
                          map.setZoom(18);
                          setMarkerActive(s.guid);
                        }}
                      >
                        <i
                          className="material-icons"
                          style={{ fontSize: "24px" }}
                        >
                          my_location
                        </i>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <h5
                            style={{
                              width: "246px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {t("lbInfo")}: {s.address}{" "}
                          </h5>
                          <h3>
                            {t("lbTime")}:{" "}
                            {moment(s.dateCreated).format("HH:mm")}
                          </h3>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Map;
