import { useRef, useState } from "react";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";

import { LinkDeviceFailed } from "../../../noticeGlobal/loginNotice";

import "../modalLinkDevice/Modal.scss";
import { useTranslation } from "react-i18next";

interface IModal {
  isOpenModal: boolean;
  onCloseCallback: (isOpen: boolean) => void;
  onCallBackGetData: (guid: string, nameDevice: string) => void;
}

const Modal = ({ isOpenModal, onCloseCallback, onCallBackGetData }: IModal) => {
  const { t } = useTranslation();
  const [content, setContent] = useState("");
  const [nameDevice, setNameDevice] = useState("");
  const linkDeviceRef = useRef<HTMLInputElement>(null);

  const onHandleData = (guid: string) => {
    if (isEmpty(guid)) {
      linkDeviceRef.current?.focus();
      toast.warning(t(LinkDeviceFailed));
      return;
    }

    onCallBackGetData(guid, nameDevice);
    setContent("");
  };

  return (
    <>
      <div
        id="myModal"
        className={`modal-device ${isOpenModal ? "block" : "none"}`}
      >
        <div className={`modal-device-content`}>
          <div className="modal-device-content-header">
            <span onClick={() => onCloseCallback(false)} className="close">
              &times;
            </span>
          </div>
          <div className="modal-device-content-body">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label style={{ marginBottom: "2px" }}>{t("lbDevice")}</label>
              <input
                ref={linkDeviceRef}
                type="text"
                value={content || ""}
                onChange={(e) => setContent(e.target.value)}
              />
            </div>
            <br />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label style={{ marginBottom: "2px" }}>{t("lbNameDevice")}</label>
              <input
                type="text"
                value={nameDevice || ""}
                onChange={(e) => setNameDevice(e.target.value)}
              />
            </div>
          </div>
          <div className="modal-device-content-footer">
            <button onClick={() => onHandleData(content)}>
              {t("btnGetDevice")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
