import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import env from "react-dotenv";
import { isUndefined } from "lodash";

import { fetchToken } from "../api/apiRefreshToken";
import apiHelper from "../api/apiHelper";
import i18n from "../i18n";

import { Location, LocationRequest } from "../interfaces/location.interface";
import { InitialState } from "../interfaces/reducer.interface";
import { APIResult } from "../interfaces/response.interface";

import { Key } from "../enums/localStorageEnum";
import { MultiLang } from "../enums/multiLangEnum";

import { setDefaultValue, setItem } from "../slices/loadingSlice";

export const fetchLocation = createAsyncThunk(
  "user/fetchLocation",
  async (data: any, { rejectWithValue, dispatch }) => {
    const {
      PageSize,
      PageNum,
      DateStart,
      DateEnd,
      AlertID,
      UserGUID,
      DeviceGUID,
    }: LocationRequest = data;
    try {
      await fetchToken();
      apiHelper.setHeader({
        Authorization: localStorage.getItem(Key.Token),
        "Accept-Language": i18n.language === MultiLang.EN ? "en-US" : "ja-JP",
      });

      const response = await apiHelper
        .handleRequest({
          method: "GET",
          url: `api/Location`,
          params: {
            PageSize,
            PageNum,
            DateStart,
            DateEnd,
            AlertID,
            UserGUID,
            DeviceGUID,
          },
        })
        .then((res) => res.data);
      dispatch(setDefaultValue({ isLoading: false }));
      return response;
    } catch (err: any) {
      dispatch(setDefaultValue({ isLoading: false }));
      let result = err.response.data as APIResult;
      if (isUndefined(result.Message)) {
        toast.error(result.title);
      } else {
        toast.error(result.Message);
      }
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState: InitialState<Location> = {
  entities: [],
  loading: "idle",
  saving: "idle",
  total: 0,
  pageNum: 0,
  pageSize: env.PAGE_SIZE,
  currentRequestId: null,
  error: null,
};

const locationSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocation.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchLocation.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          const { Data, PageNum, PageSize, Total }: APIResult = action.payload;
          state.loading = "idle";
          if (PageNum > 0) {
            state.entities = [...state.entities, ...Data] ?? [];
          } else {
            state.entities = Data ?? [];
          }
          state.total = Total;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchLocation.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export default locationSlice.reducer;
