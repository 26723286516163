import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { isUndefined } from "lodash";

import i18n from "../i18n";
import { fetchToken } from "../api/apiRefreshToken";
import apiHelper from "../api/apiHelper";

import { User, UserRequest } from "../interfaces/user.interface";
import { InitialState } from "../interfaces/reducer.interface";
import { APIResult } from "../interfaces/response.interface";

import { Key } from "../enums/localStorageEnum";
import { MultiLang } from "../enums/multiLangEnum";

import { setDefaultValue } from "../slices/loadingSlice";
import {
  resetData,
  setDefaultValue as setDefaultValueUser,
} from "./userItemSlice";

export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async (data: any, { rejectWithValue, dispatch }) => {
    const { PageSize, PageNum, DateStart, DateEnd, AlertID }: UserRequest =
      data;
    try {
      await fetchToken();
      apiHelper.setHeader({
        Authorization: localStorage.getItem(Key.Token),
        "Accept-Language": i18n.language === MultiLang.EN ? "en-US" : "ja-JP",
      });

      const response = await apiHelper
        .handleRequest({
          method: "GET",
          url: `api/User`,
          params: { PageSize, PageNum, DateStart, DateEnd, AlertID },
        })
        .then((res) => res.data);
      dispatch(setDefaultValue({ isLoading: false }));
      return response;
    } catch (err: any) {
      dispatch(setDefaultValue({ isLoading: false }));
      let result = err.response.data as APIResult;
      if (isUndefined(result.Message)) {
        toast.error(result.title);
      } else {
        toast.error(result.Message);
      }
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const putUser = createAsyncThunk(
  "user/putUser",
  async (data: any, { rejectWithValue, dispatch }) => {
    const { FullName, Phone, Address, Email, Password, GUID }: UserRequest =
      data;
    try {
      await fetchToken();
      apiHelper.setHeader({
        Authorization: localStorage.getItem(Key.Token),
        "Accept-Language": i18n.language === MultiLang.EN ? "en-US" : "ja-JP",
      });

      const response = await apiHelper
        .handleRequest({
          method: "PUT",
          url: `api/User`,
          data: { FullName, Phone, Address, Email, Password, GUID },
        })
        .then((res) => res.data);
      dispatch(setDefaultValue({ isLoading: false }));
      dispatch(resetData());
      dispatch(
        setDefaultValueUser({
          fullName: response.Data.fullName,
          phone: response.Data.phone,
          address: response.Data.address,
          email: response.Data.email,
          role: response.Data.role,
        })
      );
      await fetchToken(true);
      toast.success(response.Message);

      return true;
    } catch (err: any) {
      dispatch(setDefaultValue({ isLoading: false }));
      let result = err.response.data as APIResult;
      if (isUndefined(result.Message)) {
        toast.error(result.title);
      } else {
        toast.error(result.Message);
      }
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState: InitialState<User> = {
  entities: [],
  loading: "idle",
  saving: "idle",
  currentRequestId: null,
  error: null,
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          const { Data, PageNum, PageSize, Total }: APIResult = action.payload;
          state.loading = "idle";
          state.entities = Data ?? [];
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchUser.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export default userSlice.reducer;
