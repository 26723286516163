import axios from "axios";
import env from "react-dotenv";

const baseURL = env.API_URL || "https://localhost:44329/";

axios.interceptors.response.use(
  (res) => res,
  (err) => {
    return Promise.reject(err);
  }
);

const defaultOptions = {
  baseURL,
  method: "get",
  headers: {
    "Content-Type": "application/json",
  },
};

function setHeader(headers: any) {
  defaultOptions.headers = {
    ...defaultOptions.headers,
    ...headers,
  };
}

function handleRequest(options: any) {
  return axios.request({ ...defaultOptions, ...options });
}

// eslint-disable-next-line class-methods-use-this
function handleError(error: any) {
  // you can customize the error handler here based on your api error response
  if (error.response && error.response.data) {
    throw new Error(error.response.data.message);
  }

  throw new Error("Unknown Error");
}

export default {
  handleRequest,
  handleError,
  setHeader,
};
