export const en = {
  /* button */
  btnSignIn: "Sign In",
  btnSignUp: "Sign Up",
  btnLogout: "Logout",
  btnEditInfo: "Edit Info",
  btnDevice: "Devices",
  btnPolygon: "Polygons",
  btnNext: "Next",
  btnPrevious: "Previous",
  btnGetDevice: "Get device",
  btnLink: "Link",
  btnClone: "Clone",
  btnSave: "Save",
  btnChangePassword: "Change Password",

  /* label */
  lbPhone: "Phone",
  lbPassword: "Password",
  lbFullName: "Full Name",
  lbEmail: "Email",
  lbAddress: "Address",
  lbRePassword: "Re-Password",
  lbDevice: "Device",
  lbNameDevice: "Device Name",
  lbDateRange: "Date Range",
  lbDeviceStatus: "Device Status",
  lbStatus: "Status",
  lbTime: "Time",
  lbBattery: "Battery",
  lbInfo: "Info",
  lbDate: "Date",
  lbSelectMulti: "Select items",
  lbLanguage: "Language",
  lbRole: "Role",

  lbAll: "All",
  lbBootNotification: "Boot Notification",
  lbScheduledNotificationNight: "Scheduled Notification Night",
  lbScheduledNotificationDay: "Scheduled Notification Day",
  lbScheduledReport: "Scheduled Report",
  lbAdHocTransmission: "Ad Hoc Transmission",

  /* txt */
  txtHintSignIn: "New to GPS Drop ?",
  txtHintSignUp: "Already have account GPS Drop ?",
  txtWelcome: "Welcome back, ",
  txtSearchAddress: "Search on Google Map",

  /* fields */
  txtSerial: "Device Serial",
  txtName: "Device Name",
  txtSafeZone: "Safe Zones",
  txtLastSeen: "Last Seen",
  txtLastStatus: "Last Status",
  txtDateActive: "Date Active",
  txtAction: "Action",
  txtDateTimeFormat: "DD/MM/YYYY hh:mm:ss",
  txtDateFormat: "DD/MM/YYYY",

  /* tooltip */
  tpTracking: "Tracking device",
  tpAddPolygons: "Add polygons",
  tpCloneFirstStatement: "Clone ",
  tpCloneSecondStatement: "into other devices",

  /* notice global */
  noticeSignInSuccess: "Welcome to GPS Drop !",
  noticeSignUpSuccess: "Sign up success, you will be back to login !",
  noticeSessionExpired: "Session Expired !",
  noticeLoginFailed: "Phone or password is not corrected",
  noticeLinkDeviceFailed: "Please enter GUID device",
  noticeLinkPolygonFailed: "Please select at least 1 polygon for using",
  noticePasswordNotSame: "Password are not same",
  noticeMandatory: "is required !",
  noticeNotValid: "is not correct format !",

  /* status password */
  stsWeak: "Weak",
  stsWellDone: "Okay",
  stsShort: "Too Short",
  stsGood: "Good",
  stsStrong: "Strong",
};
