import React, { useState } from "react";
import { isEmpty, isNull } from "lodash";

import i18n from "../../i18n";

import "/node_modules/flag-icons/css/flag-icons.min.css";
import "./multiLang.scss";

const MultiLang = ({
  top,
  left,
  right,
  bottom,
  onChangeLang,
}: {
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  onChangeLang?: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [lang, setLang] = useState(i18n.language);
  const langs = [
    {
      label: "English",
      code: "en",
      icon: "fi-us",
    },
    {
      label: "日本語",
      code: "jp",
      icon: "fi-jp",
    },
  ];

  const haneleSelectCountry = (position: number) => {
    i18n.changeLanguage(langs[position].code);
    setLang(langs[position].code);

    if (onChangeLang) {
      onChangeLang();
    }
  };

  return (
    <>
      <div className="select-lang" onClick={() => setIsOpen(!isOpen)}>
        <div className="select-lang-item">
          <span
            className={`fi ${langs.find((s) => s.code === lang)?.icon} fir`}
          ></span>
        </div>
        {isOpen && (
          <div
            className="select-lang-content"
            style={{
              top: !isNull(top) && !isEmpty(top) ? top : "",
              left: !isNull(left) && !isEmpty(left) ? left : "",
              right: !isNull(right) && !isEmpty(right) ? right : "",
              bottom: !isNull(bottom) && !isEmpty(bottom) ? bottom : "",
            }}
          >
            {langs.map((s, index) => (
              <div
                key={index}
                onClick={() => haneleSelectCountry(index)}
                className="select-lang-content-item"
              >
                <span className={`fi ${s.icon}`}></span>
                <h3>{s.label}</h3>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default MultiLang;
