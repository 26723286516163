import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import env from "react-dotenv";
import moment from "moment";
import { filter, includes, isUndefined, values } from "lodash";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";

import { Device } from "../../interfaces/device.interface";
import { InitialReducer } from "../../interfaces/reducer.interface";
import { SafeZone } from "../../interfaces/safeZone.interface";

import { fetchDevice } from "../../slices/deviceSlice";
import {
  postLinkUserDevice,
  putLinkUserDevice,
} from "../../slices/linkUserDeviceSlice";
import { setDefaultValue } from "../../slices/loadingSlice";
import { fetchSafeZoneUser } from "../../slices/safeZoneUserSlice";
import { fetchSafeZone } from "../../slices/safeZoneSlice";
import {
  cloneLinkSafeZoneDevice,
  createLinkSafeZoneDevice,
} from "../../slices/linkSafeZoneDeviceSlice";
import { resetData } from "../../slices/deviceItemSlice";

import Modal from "./modalLinkDevice/Modal";
import ModalMultiSelected from "./modalMultiSelect/Modal";

import { useWindowSize } from "../../customHook/customHooks";
import { StatusDevice } from "../../enums/statusEnum";
import i18n from "../../i18n";

import "./Device.scss";

const Devices = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const [size, height] = useWindowSize();
  const device = useSelector((state: InitialReducer) => state.device);
  const linkUserDeviceItem = useSelector(
    (state: InitialReducer) => state.linkUserDeviceItem
  );
  const linkSafeZoneDeviceItem = useSelector(
    (state: InitialReducer) => state.linkSafeZoneDeviceItem
  );
  const deviceItem = useSelector((state: InitialReducer) => state.deviceItem);
  const safeZone = useSelector((state: InitialReducer) => state.safeZone);

  const [isOpenGUIDModal, setIsOpenGUIDModal] = useState(false);
  const [isOpenAddPolygonModal, setIsOpenAddPolygonModal] = useState(false);
  const [isClonePolygonModal, setIsClonePolygonModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [safeZoneItem, setSafeZoneItem] = useState<Array<string>>([]);
  const [deviceGUID, setDeviceGUID] = useState("");

  useEffect(() => {
    dispatch(setDefaultValue({ isLoading: true }));
  }, []);

  useEffect(() => {
    dispatch(
      fetchDevice({
        PageSize: env.PAGE_SIZE,
        PageNum: 0,
      })
    );

    dispatch(fetchSafeZone({ isPagination: false }));
    setSafeZoneItem([]);
    setIsEdit(false);
    setDeviceGUID("");
  }, [linkSafeZoneDeviceItem, linkUserDeviceItem, deviceItem]);

  const onHandleLink = () => {
    setIsOpenGUIDModal(!isOpenGUIDModal);
  };

  const onHandleLinkDevice = (guid: string, nameDevice: string) => {
    dispatch(setDefaultValue({ isLoading: true }));
    dispatch(postLinkUserDevice({ deviceGUID: guid, name: nameDevice }));
    setIsOpenGUIDModal(false);
  };

  const onHandleLinkPolygon = (safeZones: string[]) => {
    dispatch(
      createLinkSafeZoneDevice({ RefIDs: safeZones, DeviceGUID: deviceGUID })
    );
  };

  const onHandleClonePolygon = (cloneDevices: string[]) => {
    dispatch(
      cloneLinkSafeZoneDevice({
        DeviceGUIDTarget: deviceGUID,
        DeviceGUIDClones: cloneDevices,
      })
    );
  };

  const handleStatus = (labelId: number): string => {
    let obj = values(StatusDevice).find((s) => s.Value == labelId);
    if (!isUndefined(obj)) {
      return obj.Text;
    }

    return "";
  };

  const EditName = ({
    nameValue,
    guid,
  }: {
    nameValue: string;
    guid: string;
  }) => {
    const [name, setName] = useState(nameValue);

    return (
      <div
        style={{
          width: "150px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <input
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{ padding: "5px", border: "1px solid grey" }}
          type="text"
        />
        <button
          onClick={() => {
            dispatch(setDefaultValue({ isLoading: true }));
            dispatch(
              putLinkUserDevice({
                deviceGUID: guid,
                name: name,
              })
            );
          }}
        >
          <i
            className="material-icons"
            style={{ fontSize: "24px", color: "green" }}
          >
            check
          </i>
        </button>
        <button
          onClick={() => {
            setIsEdit(false);
            setDeviceGUID("");
            dispatch(resetData());
          }}
        >
          <i
            className="material-icons"
            style={{ fontSize: "24px", color: "red" }}
          >
            cancel
          </i>
        </button>
      </div>
    );
  };

  const deviceItems = useMemo(() => {
    return (
      <>
        {device.entities.length > 0
          ? device.entities.map((d: Device, index: number) => (
              <div key={index} className="table-mobile-container">
                <ul>
                  <li style={{ display: "flex", alignItems: "center" }}>
                    {deviceGUID === d.guid && isEdit ? (
                      <EditName
                        nameValue={d.linkUserDevices.name}
                        guid={d.linkUserDevices.guid}
                      />
                    ) : (
                      <>
                        <span>{t("txtName")}: </span>
                        {d.linkUserDevices.name}
                        <button
                          onClick={() => {
                            setIsEdit(!isEdit);
                            setDeviceGUID(d.guid);
                          }}
                        >
                          <i
                            className="material-icons"
                            style={{ fontSize: "30px" }}
                          >
                            drive_file_rename_outline
                          </i>
                        </button>
                      </>
                    )}
                  </li>
                  <li>
                    <span>{t("txtSerial")}:</span> {d.serial}
                  </li>
                  <li>
                    <span>{t("txtSafeZone")}:</span>
                    {d.linkUserDevices.linkUserDevices_SafeZones.length}
                  </li>
                  <li>
                    <span>{t("txtLastSeen")}:</span>
                    {new Date(d.linkUserDevices.lastSeen).getFullYear() === 1
                      ? ""
                      : moment(d.linkUserDevices.lastSeen).format(
                          t("txtDateTimeFormat")
                        )}
                  </li>
                  <li>
                    <span>{t("txtLastStatus")}:</span>
                    {t(handleStatus(d.linkUserDevices.lastStatus))}
                  </li>
                  <li>
                    <span>{t("txtDateActive")}:</span>
                    {moment(d.dateModified).format(t("txtDateFormat"))}
                  </li>
                </ul>

                <div>
                  <Link to={`/tracking/${d.guid}`}>
                    <button className="btn-tooltip">
                      <i
                        className="material-icons"
                        style={{ fontSize: "30px" }}
                      >
                        near_me
                      </i>
                    </button>
                  </Link>
                  <button
                    className="btn-tooltip"
                    onClick={() => {
                      setIsOpenAddPolygonModal(!isOpenAddPolygonModal);
                      setDeviceGUID(d.guid);
                      dispatch(
                        fetchSafeZoneUser({
                          deviceGUID: d.guid,
                          isPagination: false,
                        })
                      );

                      setSafeZoneItem(
                        d.linkUserDevices.linkUserDevices_SafeZones.map(
                          (item) => item["safeZoneRefID"]
                        )
                      );
                    }}
                  >
                    <i className="material-icons" style={{ fontSize: "30px" }}>
                      addchart
                    </i>
                  </button>
                  <button
                    className="btn-tooltip"
                    onClick={() => {
                      setIsClonePolygonModal(!isClonePolygonModal);
                      setDeviceGUID(d.guid);
                    }}
                  >
                    <i className="material-icons" style={{ fontSize: "30px" }}>
                      content_copy
                    </i>
                  </button>
                </div>
              </div>
            ))
          : ""}
      </>
    );
  }, [device.entities, i18n.language, isEdit]);

  return (
    <div className="container-table">
      <div>
        <button className="btn-link" onClick={onHandleLink}>
          <i className="material-icons" style={{ fontSize: "24px" }}>
            add_circle
          </i>
        </button>
      </div>
      {size >= 768 ? (
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>{t("txtSerial")}</th>
              <th>{t("txtName")}</th>
              <th>{t("txtSafeZone")}</th>
              <th>{t("txtLastSeen")}</th>
              <th>{t("txtLastStatus")}</th>
              <th>{t("txtDateActive")}</th>
              <th>{t("txtAction")}</th>
            </tr>
          </thead>
          <tbody>
            {device.entities.length > 0 ? (
              device.entities.map((d: Device, index) => (
                <tr key={d.guid}>
                  <td>{index + 1}</td>
                  <td>{d.serial}</td>
                  <td
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {deviceGUID === d.guid && isEdit ? (
                      <EditName
                        nameValue={d.linkUserDevices.name}
                        guid={d.linkUserDevices.guid}
                      />
                    ) : (
                      <>
                        <h3>{d.linkUserDevices.name}</h3>
                        <button
                          onClick={() => {
                            setIsEdit(true);
                            setDeviceGUID(d.guid);
                          }}
                        >
                          <i
                            className="material-icons"
                            style={{ fontSize: "24px" }}
                          >
                            drive_file_rename_outline
                          </i>
                        </button>
                      </>
                    )}
                  </td>
                  <td>{d.linkUserDevices.linkUserDevices_SafeZones.length}</td>
                  <td>
                    {new Date(d.linkUserDevices.lastSeen).getFullYear() === 1
                      ? ""
                      : moment(d.linkUserDevices.lastSeen).format(
                          t("txtDateTimeFormat")
                        )}
                  </td>
                  <td>{t(handleStatus(d.linkUserDevices.lastStatus))}</td>
                  <td>
                    {moment(d.dateModified).format(t("txtDateTimeFormat"))}
                  </td>
                  <td>
                    <Link to={`/tracking/${d.guid}`}>
                      <button className="btn-tooltip">
                        <span className="tooltip-content">
                          {t("tpTracking")} {d.serial}
                        </span>
                        <i
                          className="material-icons"
                          style={{ fontSize: "24px" }}
                        >
                          near_me
                        </i>
                      </button>
                    </Link>
                    <button
                      className="btn-tooltip"
                      onClick={() => {
                        setIsOpenAddPolygonModal(!isOpenAddPolygonModal);
                        setDeviceGUID(d.guid);
                        dispatch(
                          fetchSafeZoneUser({
                            deviceGUID: d.guid,
                            isPagination: false,
                          })
                        );

                        setSafeZoneItem(
                          d.linkUserDevices.linkUserDevices_SafeZones.map(
                            (item) => item["safeZoneRefID"]
                          )
                        );
                      }}
                    >
                      <span className="tooltip-content">
                        {t("tpAddPolygons")}
                      </span>
                      <i
                        className="material-icons"
                        style={{ fontSize: "24px" }}
                      >
                        addchart
                      </i>
                    </button>
                    <button
                      className="btn-tooltip"
                      onClick={() => {
                        setIsClonePolygonModal(!isClonePolygonModal);
                        setDeviceGUID(d.guid);
                      }}
                    >
                      <span className="tooltip-content">{`${t(
                        "tpCloneFirstStatement"
                      )} ${d.serial} ${t("tpCloneSecondStatement")}`}</span>
                      <i
                        className="material-icons"
                        style={{ fontSize: "24px" }}
                      >
                        content_copy
                      </i>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} style={{ textAlign: "center" }}>
                  No data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <div className="table-mobile">{deviceItems}</div>
      )}

      {device.total &&
      device.pageSize &&
      Math.ceil(device.total / device.pageSize) > 0 ? (
        <div className="pagination-container">
          <ReactPaginate
            pageCount={Math.ceil(device.total / device.pageSize)}
            onPageChange={(val) => {
              dispatch(
                fetchDevice({
                  PageSize: env.PAGE_SIZE,
                  PageNum: val.selected,
                })
              );
            }}
            previousLabel={t("btnNext")}
            nextLabel={t("btnPrevious")}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active-pagination"
          ></ReactPaginate>
        </div>
      ) : (
        ""
      )}

      {isOpenGUIDModal && (
        <Modal
          isOpenModal={isOpenGUIDModal}
          onCloseCallback={setIsOpenGUIDModal}
          onCallBackGetData={onHandleLinkDevice}
        />
      )}

      {isOpenAddPolygonModal && (
        <ModalMultiSelected<SafeZone>
          isOpenModal={isOpenAddPolygonModal}
          onCloseCallback={setIsOpenAddPolygonModal}
          onCallBackGetData={onHandleLinkPolygon}
          listPolygon={safeZone.entities}
          listSelectedPolygon={filter(safeZone.entities, (item) =>
            includes(safeZoneItem, item.polygonID)
          )}
          nameKey="polygonID"
          nameValue="name"
          nameTextBox="btnLink"
        />
      )}

      {isClonePolygonModal && (
        <ModalMultiSelected<Device>
          isOpenModal={isClonePolygonModal}
          onCloseCallback={setIsClonePolygonModal}
          onCallBackGetData={onHandleClonePolygon}
          listPolygon={device.entities.filter((s) => s.guid !== deviceGUID)}
          listSelectedPolygon={[]}
          nameKey="guid"
          nameValue="serial"
          nameTextBox="btnClone"
        />
      )}
    </div>
  );
};

export default Devices;
