import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { isUndefined } from "lodash";

import i18n from "../i18n";
import apiHelper from "../api/apiHelper";

import { InitialState } from "../interfaces/reducer.interface";
import { APIResult } from "../interfaces/response.interface";
import {
  LinkSafeZoneDeviceRequest,
  SafeZone,
} from "../interfaces/safeZone.interface";

import { Key } from "../enums/localStorageEnum";
import { fetchToken } from "../api/apiRefreshToken";

import { setDefaultValue } from "./loadingSlice";
import {
  setDefaultValue as setDefaultValueLinkSafeZoneDevice,
  resetData,
} from "./linkSafeZoneDeviceItemSlice";

import { MultiLang } from "../enums/multiLangEnum";

export const createLinkSafeZoneDevice = createAsyncThunk(
  "safeZone/createLinkSafeZoneDevice",
  async (data: any, { rejectWithValue, dispatch }) => {
    const { RefIDs, DeviceGUID }: LinkSafeZoneDeviceRequest = data;
    try {
      await fetchToken();
      apiHelper.setHeader({
        Authorization: localStorage.getItem(Key.Token),
        "Accept-Language": i18n.language === MultiLang.EN ? "en-US" : "ja-JP",
      });

      const response = await apiHelper
        .handleRequest({
          method: "POST",
          url: `api/SafeZoneUser`,
          data: {
            RefIDs: RefIDs,
            DeviceGUID: DeviceGUID,
          },
        })
        .then((res) => res.data);
      dispatch(setDefaultValue({ isLoading: false }));
      dispatch(resetData());
      dispatch(setDefaultValueLinkSafeZoneDevice(response.Data));
      toast.success(response.Message);
      return response;
    } catch (err: any) {
      dispatch(setDefaultValue({ isLoading: false }));
      dispatch(resetData());
      let result = err.response.data as APIResult;
      if (isUndefined(result.Message)) {
        toast.error(result.title);
      } else {
        toast.error(result.Message);
      }
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const cloneLinkSafeZoneDevice = createAsyncThunk(
  "safeZone/cloneLinkSafeZoneDevice",
  async (data: any, { rejectWithValue, dispatch }) => {
    const { DeviceGUIDTarget, DeviceGUIDClones }: LinkSafeZoneDeviceRequest =
      data;
    try {
      await fetchToken();
      apiHelper.setHeader({
        Authorization: localStorage.getItem(Key.Token),
        "Accept-Language": i18n.language === MultiLang.EN ? "en-US" : "ja-JP",
      });

      const response = await apiHelper
        .handleRequest({
          method: "PUT",
          url: `api/SafeZoneUser`,
          data: {
            DeviceGUIDTarget: DeviceGUIDTarget,
            DeviceGUIDClones: DeviceGUIDClones,
          },
        })
        .then((res) => res.data);
      dispatch(setDefaultValue({ isLoading: false }));
      return response;
    } catch (err: any) {
      dispatch(setDefaultValue({ isLoading: false }));
      let result = err.response.data as APIResult;
      if (isUndefined(result.Message)) {
        toast.error(result.title);
      } else {
        toast.error(result.Message);
      }
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState: InitialState<LinkSafeZoneDeviceRequest> = {
  entities: [],
  loading: "idle",
  saving: "idle",
  currentRequestId: null,
  error: null,
};

const linkSafeZoneDeviceSlice = createSlice({
  name: "linkSafeZoneDevices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // builder
    //   .addCase(createLinkSafeZoneDevice.pending, (state, action) => {
    //     if (state.loading === "idle") {
    //       state.loading = "pending";
    //       state.currentRequestId = action.meta.requestId;
    //     }
    //   })
    //   .addCase(createLinkSafeZoneDevice.fulfilled, (state, action) => {
    //     const { requestId } = action.meta;
    //     if (
    //       state.loading === "pending" &&
    //       state.currentRequestId === requestId
    //     ) {
    //       const { Data, PageNum, PageSize, Total }: APIResult = action.payload;
    //       state.loading = "idle";
    //       state.entities = Data ?? [];
    //       state.currentRequestId = undefined;
    //     }
    //   })
    //   .addCase(createLinkSafeZoneDevice.rejected, (state, action) => {
    //     const { requestId } = action.meta;
    //     if (
    //       state.loading === "pending" &&
    //       state.currentRequestId === requestId
    //     ) {
    //       state.loading = "idle";
    //       state.error = action.error;
    //       state.currentRequestId = undefined;
    //     }
    //   });
  },
});

export default linkSafeZoneDeviceSlice.reducer;
