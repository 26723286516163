import React, { useEffect, useState } from "react";
import { useNavigate, Outlet, Link, useLocation } from "react-router-dom";
import env from "react-dotenv";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { tz } from "moment-timezone";
import { useTranslation } from "react-i18next";

import { resetData, setDefaultValue } from "../../slices/userItemSlice";
import { signOut } from "../../slices/authenticationSlice";

import { useWindowSize } from "../../customHook/customHooks";
import { Key } from "../../enums/localStorageEnum";
import { SessionExpired } from "../../noticeGlobal/loginNotice";
import { parseJwt } from "../../helper/utilityHelper";
import MultiLang from "../../components/multiLang/MultiLang";

import { InitialReducer } from "../../interfaces/reducer.interface";

import logo from "../../static/logo.png";
import logoMobile from "../../static/logo-mobile.png";
import "./Home.scss";

const Region = env.REGION || "Asia/Ho_Chi_Minh";

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<any>();
  const [size, height] = useWindowSize();
  const [isHide, setIsHide] = useState(false);
  const [isOpenNav, setIsOpenNav] = useState(false);

  const userItem = useSelector((state: InitialReducer) => state.userItem);

  useEffect(() => {
    if (location.pathname === "/") {
      setIsHide(false);
    } else {
      setIsHide(true);
    }

    if (localStorage.getItem(Key.Token)) {
      if (
        moment(localStorage.getItem(Key.TimeSession)).tz(env.REGION).toDate() <
        tz(Region).toDate()
      ) {
        localStorage.setItem(Key.Token, "");
        localStorage.setItem(Key.TimeExpired, "");
        localStorage.setItem(Key.RefreshToken, "");
        localStorage.setItem(Key.TimeSession, "");
        toast.info(t(SessionExpired));
        navigate("/login");
        return;
      }
    } else {
      navigate("/login");
      return;
    }
  }, [location]);

  useEffect(() => {
    let userInfo = parseJwt(localStorage.getItem(Key.Token));
    if (userInfo) {
      dispatch(setDefaultValue(userInfo));
    }
  }, []);

  const handleLogout = () => {
    localStorage.setItem(Key.Token, "");
    localStorage.setItem(Key.RefreshToken, "");
    dispatch(signOut(null));
    navigate("/login");
  };

  return (
    <>
      <div className="nav">
        {size >= 768 ? (
          <ul className="nav-list">
            <li>
              <Link to="/">
                <img src={logo} width="25" />
              </Link>
            </li>
            <li>
              <Link to="/devices">{t("btnDevice")}</Link>
            </li>
            <li>
              <Link to="/polygons">{t("btnPolygon")}</Link>
            </li>
            <li>
              <div className="dropdown">
                <span>
                  {userItem.role}, {userItem.fullName}
                </span>
                <div className="dropdown-content-custom">
                  <ul>
                    <li>
                      <Link to="/profile">{t("btnEditInfo")}</Link>
                    </li>
                    <li>
                      <a onClick={() => handleLogout()}>{t("btnLogout")}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <MultiLang
                right="0"
                onChangeLang={() => setIsOpenNav(!isOpenNav)}
              />
            </li>
          </ul>
        ) : (
          <>
            <div className="nav-mobile">
              <button onClick={() => setIsOpenNav(!isOpenNav)}>
                <i className="material-icons" style={{ fontSize: "40px" }}>
                  menu
                </i>
              </button>
              <Link to="/">
                <img src={logoMobile} width="30px" />
              </Link>
              <button onClick={() => handleLogout()}>{t("btnLogout")}</button>
            </div>
          </>
        )}
      </div>
      <div className="body">
        <div className="body-content">
          {!isHide ? (
            <h4 className="dashboard">
              {t("txtWelcome")} {userItem.fullName}
            </h4>
          ) : (
            <div className="body-content-item">
              <Outlet />
            </div>
          )}

          {size < 768 && (
            <>
              <div
                className={`nav-mobile-container ${
                  isOpenNav ? "nav-open" : "nav-close"
                }`}
              >
                <div className="nav-mobile-container-item">
                  <Link to="/profile" onClick={() => setIsOpenNav(!isOpenNav)}>
                    {t("btnEditInfo")}
                  </Link>
                </div>
                <div className="nav-mobile-container-item">
                  <Link to="/devices" onClick={() => setIsOpenNav(!isOpenNav)}>
                    {t("btnDevice")}
                  </Link>
                </div>
                <div className="nav-mobile-container-item">
                  <Link to="/polygons" onClick={() => setIsOpenNav(!isOpenNav)}>
                    {t("btnPolygon")}
                  </Link>
                </div>
                <div className="nav-mobile-container-item">
                  <a>{t("lbLanguage")}</a>
                  <MultiLang
                    right="0"
                    onChangeLang={() => setIsOpenNav(!isOpenNav)}
                  />
                </div>
              </div>
            </>
          )}

          <footer className="footer">
            <p>Copyright by © Nichietsu, 2023</p>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Home;
