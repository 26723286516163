export enum Status {
  OK = 200,
  Failed = 400,
  Error = 0,
}

export const StatusDevice = {
  All: {
    Value: 999,
    Text: "lbAll",
  },
  BootNotification: {
    Value: 0,
    Text: "lbBootNotification",
  },
  ScheduledNotificationNight: {
    Value: 1,
    Text: "lbScheduledNotificationNight",
  },
  ScheduledNotificationDay: {
    Value: 2,
    Text: "lbScheduledNotificationDay",
  },
  ScheduledReport: {
    Value: 3,
    Text: "lbScheduledReport",
  },
  AdHocTransmission: {
    Value: 4,
    Text: "lbAdHocTransmission",
  },
};
