import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { isEmpty, isNull } from "lodash";
import PasswordStrengthBar from "react-password-strength-bar";

import { UserSignUpRequest } from "../../interfaces/login.interface";
import { InitialReducer } from "../../interfaces/reducer.interface";

import { setDefaultValue } from "../../slices/loadingSlice";
import { putUser } from "../../slices/userSlice";

import { isValidEmail } from "../../helper/utilityHelper";
import {
  FieldIsMandatory,
  FieldIsNotValid,
  PasswordNotSame,
} from "../../noticeGlobal/loginNotice";

import "./Profile.scss";

const Profile = () => {
  const [isChangePassword, setIsChangePassword] = useState(false);
  const userItem = useSelector((state: InitialReducer) => state.userItem);
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const fullNameRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const rePasswordRef = useRef<HTMLInputElement>(null);

  const formik = useFormik<UserSignUpRequest>({
    initialValues: {
      FullName: userItem.fullName,
      GUID: userItem.guid,
      Phone: userItem.phone,
      Email: userItem.email,
      Address: userItem.address,
      Password: "",
      RePassword: "",
      Role: userItem.role,
    },
    onSubmit: (values) => {
      let validate = handleValidateForm(values);
      if (!validate) {
        return;
      }

      if (isChangePassword && values.Password !== values.RePassword) {
        toast.warning(t(PasswordNotSame));
        return;
      }

      dispatch(setDefaultValue({ isLoading: true }));
      dispatch(putUser(values));
    },
  });

  const handleValidateForm = (values: UserSignUpRequest): boolean => {
    let isMissing = true;
    if (isNull(values.FullName) || isEmpty(values.FullName)) {
      isMissing = false;
      toast.warning(`${t("lbFullName")} ${t(FieldIsMandatory)}`);
      if (fullNameRef.current) {
        fullNameRef.current.focus();
      }

      return isMissing;
    }

    if (isNull(values.Phone) || isEmpty(values.Phone)) {
      isMissing = false;
      toast.warning(`${t("lbPhone")} ${t(FieldIsMandatory)}`);
      if (phoneRef.current) {
        phoneRef.current.focus();
      }

      return isMissing;
    }

    if (isNull(values.Email) || isEmpty(values.Email)) {
      isMissing = false;
      toast.warning(`${t("lbEmail")} ${t(FieldIsMandatory)}`);
      if (emailRef.current) {
        emailRef.current.focus();
      }

      return isMissing;
    }

    if (!isValidEmail(values.Email)) {
      isMissing = false;
      toast.warning(`${t("lbEmail")} ${t(FieldIsNotValid)}`);
      if (emailRef.current) {
        emailRef.current.focus();
      }

      return isMissing;
    }

    if (isChangePassword) {
      if (isNull(values.Password) || isEmpty(values.Password.trim())) {
        isMissing = false;
        toast.warning(`${t("lbPassword")} ${t(FieldIsMandatory)}`);
        return isMissing;
      }

      if (isNull(values.RePassword) || isEmpty(values.RePassword.trim())) {
        isMissing = false;
        toast.warning(`${t("lbRePassword")} ${t(FieldIsMandatory)}`);
        return isMissing;
      }
    }

    return isMissing;
  };

  return (
    <>
      <div className="profile-container">
        <form className="profile-container-item" onSubmit={formik.handleSubmit}>
          <div className="form-sign-up-input mb-15">
            <label>
              {t("lbFullName")}
              <span className="mandatory">*</span>
            </label>
            <input
              ref={fullNameRef}
              name="FullName"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.FullName}
            />
          </div>
          <div className="form-sign-up-input mb-15">
            <label>{t("lbRole")}</label>
            <input
              style={{ backgroundColor: "#a7a3a3d1" }}
              type="text"
              value={formik.values.Role}
              readOnly={true}
            />
          </div>
          <div className="form-sign-up-input mb-15">
            <label>
              {t("lbPhone")}
              <span className="mandatory">*</span>
            </label>
            <input
              ref={phoneRef}
              name="Phone"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.Phone}
            />
          </div>
          <div className="form-sign-up-input mb-15">
            <label>
              {t("lbEmail")}
              <span className="mandatory">*</span>
            </label>
            <input
              ref={emailRef}
              name="Email"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.Email}
            />
          </div>
          <div className="form-sign-up-input mb-15">
            <label>{t("lbAddress")}</label>
            <input
              name="Address"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.Address}
            />
          </div>

          {!isChangePassword && (
            <div className="form-sign-up-input mb-15">
              <button
                className="btn profile-container-btn"
                onClick={() => setIsChangePassword(!isChangePassword)}
              >
                {t("btnChangePassword")}
              </button>
            </div>
          )}

          {isChangePassword && (
            <>
              <div className="form-sign-up-input mb-5">
                <label>
                  {t("lbPassword")}
                  <span className="mandatory">*</span>
                </label>
                <input
                  ref={passwordRef}
                  name="Password"
                  type="password"
                  onChange={formik.handleChange}
                  value={formik.values.Password}
                />
                <PasswordStrengthBar
                  password={formik.values.Password}
                  scoreWords={[
                    t("stsWeak"),
                    t("stsWeak"),
                    t("stsWellDone"),
                    t("stsGood"),
                    t("stsStrong"),
                  ]}
                  shortScoreWord={t("stsShort")}
                />
              </div>

              <div className="form-sign-up-input mb-15">
                <label>
                  {t("lbRePassword")}
                  <span className="mandatory">*</span>
                </label>
                <input
                  ref={rePasswordRef}
                  name="RePassword"
                  type="password"
                  onChange={formik.handleChange}
                  value={formik.values.RePassword}
                />
              </div>
            </>
          )}
          <div className="form-sign-up-input mb-15">
            <button type="submit" className="btn profile-container-btn">
              {t("btnSave")}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Profile;
