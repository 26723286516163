import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { isUndefined } from "lodash";

import i18n from "../i18n";
import { fetchToken } from "../api/apiRefreshToken";
import apiHelper from "../api/apiHelper";

import { InitialState } from "../interfaces/reducer.interface";
import { APIResult } from "../interfaces/response.interface";

import { Key } from "../enums/localStorageEnum";
import { MultiLang } from "../enums/multiLangEnum";

import { setDefaultValue } from "../slices/loadingSlice";
import {
  setDefaultValue as setDefaultValueSafeZone,
  resetData as resetDataSafeZone,
} from "./safeZoneItemSlice";

import {
  Polygon,
  PolygonRequest,
  SafeZone,
} from "../interfaces/safeZone.interface";
import { Status } from "../enums/statusEnum";

export const fetchSafeZone = createAsyncThunk(
  "safeZone/fetchSafeZone",
  async (data: any, { rejectWithValue, dispatch }) => {
    const { PageSize, PageNum, deviceGUID, isPagination }: SafeZone = data;
    try {
      await fetchToken();
      apiHelper.setHeader({
        Authorization: localStorage.getItem(Key.Token),
        "Accept-Language": i18n.language === MultiLang.EN ? "en-US" : "ja-JP",
      });

      const response = await apiHelper
        .handleRequest({
          method: "GET",
          url: `api/SafeZone`,
          params: {
            PageSize: PageSize,
            PageNum: PageNum,
            DeviceGUID: deviceGUID,
            IsPagination: isPagination,
          },
        })
        .then((res) => res.data);
      dispatch(setDefaultValue({ isLoading: false }));
      return response;
    } catch (err: any) {
      dispatch(setDefaultValue({ isLoading: false }));
      let result = err.response.data as APIResult;
      if (isUndefined(result.Message)) {
        toast.error(result.title);
      } else {
        toast.error(result.Message);
      }
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSafeZone = createAsyncThunk(
  "safeZone/createSafeZone",
  async (data: Array<PolygonRequest>, { rejectWithValue, dispatch }) => {
    try {
      await fetchToken();
      apiHelper.setHeader({
        Authorization: localStorage.getItem(Key.Token),
        "Accept-Language": i18n.language === MultiLang.EN ? "en-US" : "ja-JP",
      });

      const response = await apiHelper
        .handleRequest({
          method: "POST",
          url: `api/SafeZone`,
          data: data,
        })
        .then((res) => res.data);
      dispatch(setDefaultValue({ isLoading: false }));
      dispatch(resetDataSafeZone());
      if (response.StatusCode === Status.OK) {
        dispatch(setDefaultValueSafeZone(response.Data));
        toast.success(response.Message);
      } else {
        toast.error(response.Message);
      }
      return true;
    } catch (err: any) {
      dispatch(setDefaultValue({ isLoading: false }));
      let result = err.response.data as APIResult;
      toast.error(result.Message);
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSafeZone = createAsyncThunk(
  "safeZone/deleteSafeZone",
  async (data: any, { rejectWithValue, dispatch }) => {
    const { refIDs }: Polygon = data;
    try {
      await fetchToken();
      apiHelper.setHeader({
        Authorization: localStorage.getItem(Key.Token),
        "Accept-Language": i18n.language === MultiLang.EN ? "en-US" : "ja-JP",
      });

      const response = await apiHelper
        .handleRequest({
          method: "DELETE",
          url: `api/SafeZone`,
          params: {
            refId: refIDs,
          },
        })
        .then((res) => res.data);
      dispatch(setDefaultValue({ isLoading: false }));
      dispatch(resetDataSafeZone());
      return response;
    } catch (err: any) {
      dispatch(setDefaultValue({ isLoading: false }));
      dispatch(resetDataSafeZone());
      let result = err.response.data as APIResult;
      if (isUndefined(result.Message)) {
        toast.error(result.title);
      } else {
        toast.error(result.Message);
      }
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState: InitialState<SafeZone> = {
  entities: [],
  loading: "idle",
  saving: "idle",
  currentRequestId: null,
  error: null,
};

const safeZoneSlice = createSlice({
  name: "safeZones",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSafeZone.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchSafeZone.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          const { Data, PageNum, PageSize, Total }: APIResult = action.payload;
          state.loading = "idle";
          state.entities = Data ?? [];
          state.total = Total;
          state.pageNum = PageNum;
          state.pageSize = PageSize;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchSafeZone.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export default safeZoneSlice.reducer;
