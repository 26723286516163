import {
  GoogleMap,
  DrawingManagerF,
  PolygonF,
  StandaloneSearchBoxProps,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { groupBy, isEmpty, isNull, isUndefined, sampleSize } from "lodash";
import env from "react-dotenv";

import { useWindowSize } from "../../customHook/customHooks";

import {
  createSafeZone,
  deleteSafeZone,
  fetchSafeZone,
} from "../../slices/safeZoneSlice";
import { setDefaultValue } from "../../slices/loadingSlice";
import { setDefaultValue as setDefaultSafeZoneValue } from "../../slices/safeZoneItemSlice";

import { PolygonRequest, SafeZone } from "../../interfaces/safeZone.interface";
import { InitialReducer } from "../../interfaces/reducer.interface";
import { Polygon as IPolygon } from "../../interfaces/safeZone.interface";

import navLeft from "../../static/angles-right-solid.svg";
import "./Polygon.scss";
import { t } from "i18next";

const Polygon = () => {
  const dispatch = useDispatch<any>();
  const [size, height] = useWindowSize();
  const [map, setMap] = useState<any>();
  const [drawing, setDrawing] = useState<any>();
  const [polygon, setPolygon] = useState<any>();
  const [shape, setShape] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(true);
  const [position, setPosition] = useState({
    lat: 35.678975150911555,
    lng: 139.76323706941017,
  });

  const [zoom, setZoom] = useState(15);
  const [name, setName] = useState("");
  const [positionPolygon, setPositionPolygon] = useState("");
  const [isLock, setIsLock] = useState(false);
  const [page, setPage] = useState(0);

  const safeZone = useSelector((state: InitialReducer) => state.safeZone);
  const safeZoneItem = useSelector(
    (state: InitialReducer) => state.safeZoneItem
  );
  const loader = useSelector((state: InitialReducer) => state.loader);

  const containerStyle = {
    width: "100%",
    height: size > 426 ? "calc(100vh - 140px)" : "calc(100vh - 300px)",
  };

  useEffect(() => {
    dispatch(setDefaultValue({ isLoading: true }));
  }, []);

  useEffect(() => {
    dispatch(
      fetchSafeZone({
        PageNum: page,
        PageSize: env.PAGE_SIZE_SCROLL,
        isPagination: true,
      })
    );
    handleClearPolygons();
  }, [safeZoneItem, page]);

  const onHandleCreatePolygon = (arr: any) => {
    let shape: Array<PolygonRequest> = [];
    for (let i = 0; i < arr.length; i++) {
      let point: PolygonRequest = {
        Name: name,
        Latitude: arr.getAt(i).lat(),
        Longitude: arr.getAt(i).lng(),
      };
      shape.push(point);
    }

    dispatch(createSafeZone(shape));
    setIsLock(!isLock);
    setName("");
  };

  const handleClearPolygons = (): void => {
    if (polygon) {
      polygon.setMap(null);
      setPolygon(null);
    }
  };

  const handleDrawPolygon = (polygon: Array<IPolygon>) => {
    const item = new Array<{ lat: number; lng: number }>();
    polygon.forEach((s: IPolygon) => {
      item.push({ lat: s.latitude, lng: s.longitude });
    });

    setShape(item);
    setPosition(sampleSize(item, 1)[0]);
    map.setZoom(18);
  };

  const handleRemovePolygon = (polygonID: string) => {
    dispatch(setDefaultValue({ isLoading: true }));

    //Create a temporary for reload page (deleteSafeZone will reset data)
    const polygon = new Array<IPolygon>();
    polygon.push({
      safeZoneID: 0,
      refIDs: polygonID,
      name: "",
      latitude: 0,
      longitude: 0,
    });

    dispatch(setDefaultSafeZoneValue({ polygonID: polygonID }));
    dispatch(deleteSafeZone({ refIDs: polygonID }));
  };

  const handleScroll = (e: any) => {
    const scrollTop = e.target.scrollTop;
    const clientHeight = e.target.clientHeight;
    const scrollHeight = e.target.scrollHeight;
    let total = scrollTop + clientHeight;

    if (scrollHeight - total <= 1 && scrollHeight - total >= 0) {
      if (safeZone.entities.length === safeZone.total) {
        return;
      }

      let pageIncrease = page + 1;
      setPage(pageIncrease);
    }
  };

  const searchBoxRef = useRef<any>();
  const positions = { lat: -25.344, lng: 131.031 };
  return (
    <div className="container">
      <GoogleMap
        options={{
          draggableCursor: "default",
          draggingCursor: "pointer",
          fullscreenControl: false,
        }}
        center={position}
        zoom={zoom}
        mapContainerStyle={containerStyle}
        onLoad={(item) => setMap(item)}
      >
        <div className="search-box">
          <StandaloneSearchBox
            ref={searchBoxRef}
            bounds={new google.maps.LatLngBounds(positions)}
            onPlacesChanged={() => {
              const data =
                searchBoxRef.current.state.searchBox.getPlaces() || [];
              if (data.length > 0) {
                setPosition({
                  lat: data[0].geometry.location.lat(),
                  lng: data[0].geometry.location.lng(),
                });
              }
            }}
          >
            <input
              className="search-box-input"
              type="text"
              placeholder={t("txtSearchAddress")}
            />
          </StandaloneSearchBox>
        </div>
        <DrawingManagerF
          onLoad={(item) => setDrawing(item)}
          onOverlayComplete={(item) => {
            drawing.setDrawingMode(null);

            var newShape: any = item.overlay;
            newShape.type = item.type;
            newShape.setEditable(true);
            setPolygon(newShape);
            setIsLock(true);
          }}
          options={{
            polygonOptions: {
              draggable: true,
              fillColor: "#eeb557",
              fillOpacity: 0.2,
              strokeWeight: 2,
              strokeColor: "#eeb557",
              editable: true,
              zIndex: 1,
              geodesic: true,
            },
            drawingControl: false,
            drawingControlOptions: {
              position: google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [google.maps.drawing.OverlayType.POLYGON],
            },
          }}
        ></DrawingManagerF>
        <PolygonF
          options={{
            draggable: false,
            fillColor: "#eeb557",
            fillOpacity: 0.2,
            strokeWeight: 2,
            strokeColor: "#eeb557",
            editable: false,
            zIndex: 1,
            geodesic: true,
          }}
          path={shape}
        ></PolygonF>
      </GoogleMap>

      {size >= 768 ? (
        <>
          <div className={`container-list-item ${!isOpen ? "closed" : ""}`}>
            <div className="container-list-item-btn-polygon">
              <button
                className={`btn ${isLock ? "lock" : ""}`}
                onClick={() => {
                  drawing.setMap(map);
                  drawing.setDrawingMode(
                    google.maps.drawing.OverlayType.POLYGON
                  );
                }}
              >
                <i className="material-icons" style={{ fontSize: "24px" }}>
                  add_circle
                </i>
              </button>
              <button
                className="btn"
                onClick={() => {
                  handleClearPolygons();
                  drawing.setMap(null);
                  setIsLock(false);
                  setName("");
                }}
              >
                <i className="material-icons" style={{ fontSize: "24px" }}>
                  autorenew
                </i>
              </button>
            </div>
            <div
              className="container-list-item-detail"
              onScroll={(e) => handleScroll(e)}
            >
              <ul className="list-item">
                {polygon && (
                  <li>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <input
                          style={{
                            padding: "8px",
                            border: "1px solid #eeb557",
                          }}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          type="text"
                        />
                      </div>
                      <button
                        className="btn"
                        onClick={() => onHandleCreatePolygon(polygon.getPath())}
                      >
                        <i
                          className="material-icons"
                          style={{ fontSize: "24px" }}
                        >
                          check
                        </i>
                      </button>
                    </div>
                  </li>
                )}
                {safeZone.entities.length > 0
                  ? safeZone.entities.map((s: SafeZone) => (
                      <li key={s.polygonID}>
                        <h3
                          className={`${
                            positionPolygon == s.polygonID ? "bold" : ""
                          }`}
                        >
                          {s.name}
                        </h3>
                        <div>
                          <button
                            onClick={() => {
                              handleRemovePolygon(s.polygonID);
                              dispatch(
                                fetchSafeZone({ pageNum: 0, pageSize: 20 })
                              );
                            }}
                            className="btn btn-small"
                          >
                            <i
                              className="material-icons"
                              style={{ fontSize: "24px" }}
                            >
                              delete
                            </i>
                          </button>
                          <button
                            className={`btn btn-small ${
                              positionPolygon == s.polygonID ? "lock" : ""
                            }`}
                            onClick={() => {
                              handleDrawPolygon(
                                groupBy(s.polygon, "refIDs")[s.polygonID]
                              );

                              setPositionPolygon(s.polygonID);
                            }}
                          >
                            <i
                              className="material-icons"
                              style={{ fontSize: "24px" }}
                            >
                              near_me
                            </i>
                          </button>
                        </div>
                      </li>
                    ))
                  : ""}
              </ul>
            </div>
          </div>
          <button
            className={`button-close ${!isOpen ? "active" : ""}`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <img src={navLeft} width={15} height={15} />
          </button>
        </>
      ) : (
        <>
          <div className="container-list-item-detail-mobile">
            <div className="container-list-item-detail-mobile-header">
              <button
                className={`btn ${isLock ? "lock" : ""}`}
                onClick={() => {
                  drawing.setMap(map);
                  drawing.setDrawingMode(
                    google.maps.drawing.OverlayType.POLYGON
                  );
                }}
              >
                <i className="material-icons" style={{ fontSize: "24px" }}>
                  add_circle
                </i>
              </button>
              <button
                className="btn"
                onClick={() => {
                  handleClearPolygons();
                  drawing.setMap(null);
                  setIsLock(false);
                  setName("");
                }}
              >
                <i className="material-icons" style={{ fontSize: "24px" }}>
                  autorenew
                </i>
              </button>
            </div>
            <div className="container-list-item-detail-mobile-body">
              <div onScroll={(e) => handleScroll(e)}>
                <ul className="list-item">
                  {polygon && (
                    <li>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <input
                            style={{
                              padding: "8px",
                              border: "1px solid #eeb557",
                            }}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                          />
                        </div>
                        <button
                          className="btn"
                          onClick={() =>
                            onHandleCreatePolygon(polygon.getPath())
                          }
                        >
                          <i
                            className="material-icons"
                            style={{ fontSize: "24px" }}
                          >
                            check
                          </i>
                        </button>
                      </div>
                    </li>
                  )}
                  {safeZone.entities.length > 0
                    ? safeZone.entities.map((s: SafeZone) => (
                        <li key={s.polygonID}>
                          <h3
                            className={`${
                              positionPolygon == s.polygonID ? "bold" : ""
                            }`}
                          >
                            {s.name}
                          </h3>
                          <div>
                            <button
                              onClick={() => {
                                handleRemovePolygon(s.polygonID);
                                dispatch(
                                  fetchSafeZone({ pageNum: 0, pageSize: 20 })
                                );
                              }}
                              className="btn btn-small"
                            >
                              <i
                                className="material-icons"
                                style={{ fontSize: "24px" }}
                              >
                                delete
                              </i>
                            </button>
                            <button
                              className={`btn btn-small ${
                                positionPolygon == s.polygonID ? "lock" : ""
                              }`}
                              onClick={() => {
                                handleDrawPolygon(
                                  groupBy(s.polygon, "refIDs")[s.polygonID]
                                );

                                setPositionPolygon(s.polygonID);
                              }}
                            >
                              <i
                                className="material-icons"
                                style={{ fontSize: "24px" }}
                              >
                                near_me
                              </i>
                            </button>
                          </div>
                        </li>
                      ))
                    : ""}
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Polygon;
