export const jp = {
  /* button */
  btnSignIn: "サインイン",
  btnSignUp: "サインアップ",
  btnLogout: "ログアウト",
  btnEditInfo: "情報編集",
  btnDevice: "デバイス",
  btnPolygon: "地帯設定",
  btnNext: "次へ",
  btnPrevious: "前へ",
  btnGetDevice: "デバイス取得",
  btnLink: "リンク",
  btnClone: "クローン",
  btnSave: "保存",
  btnChangePassword: "パスワードを変更する",

  /* label */
  lbPhone: "電話",
  lbPassword: "パスワード",
  lbFullName: "フルネーム",
  lbEmail: "Eメール",
  lbAddress: "住所",
  lbRePassword: "確認パスワード",
  lbDevice: "デバイス",
  lbNameDevice: "デバイス名",
  lbDateRange: "日付間隔",
  lbDeviceStatus: "デバイス状況",
  lbStatus: "ステータス",
  lbTime: "時間",
  lbBattery: "電池電圧",
  lbInfo: "情報",
  lbDate: "日付",
  lbLanguage: "言語",
  lbRole: "役割",

  lbAll: "全て",
  lbBootNotification: "起動",
  lbScheduledNotificationNight: "定時通知(夜間)",
  lbScheduledNotificationDay: "定時通知(昼間)",
  lbScheduledReport: "定時報告(時刻未取得)",
  lbAdHocTransmission: "臨時送信",
  /* txt */
  txtHintSignIn: "GPS Dropを初めてご利用ですか？",
  txtHintSignUp: "GPS Dropのアカウントをすでにお持ちですか？",
  txtWelcome: "ようこそ、",
  txtFooter: "Copyright by © Nichietsu, 2023",

  /* fields */
  txtSerial: "設備シリアル",
  txtName: "デバイス名",
  txtSafeZone: "安全地帯",
  txtLastSeen: "最終観測",
  txtLastStatus: "最終ステータス",
  txtDateActive: "有効日",
  txtAction: "アクション",
  txtDateTimeFormat: "YYYY年MM月DD日 hh:mm:ss",
  txtDateFormat: "YYYY年MM月DD日",
  txtSearchAddress: "Googleマップで探す",

  /* tooltip */
  tpTracking: "設備トラッキング",
  tpAddPolygons: "ポリゴン追加",
  tpCloneFirstStatement: "別のデバイスにクローン",
  tpCloneSecondStatement: "",

  /* notice global */
  noticeSignInSuccess: "ようこそ GPS Drop !",
  noticeSignUpSuccess: "登録成功, ログイン画面へ戻ります!",
  noticeSessionExpired: "セッション切断!",
  noticeLoginFailed: "電話番号もしくはパスワードが正しくありません。",
  noticeLinkDeviceFailed: "GUIDデバイスを入力してください。",
  noticeLinkPolygonFailed: "使用地帯を選択してください。",
  noticePasswordNotSame: "パスワードが正しくありません。",
  noticeMandatory: "必須入力項目!",
  noticeNotValid: "正しい形式ではありません !",

  /* status password */
  stsWeak: "Weak",
  stsWellDone: "Okay",
  stsShort: "Too Short",
  stsGood: "Good",
  stsStrong: "Strong",
};
