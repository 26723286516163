import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiHelper from "../api/apiHelper";
import i18n from "../i18n";

import { InitialStateAuthentication } from "../interfaces/reducer.interface";
import {
  Authentication,
  Login,
  UserSignUpRequest,
} from "../interfaces/login.interface";
import { Key } from "../enums/localStorageEnum";
import { resetData, setDefaultValue } from "../slices/userItemSlice";
import { setDefaultValue as setLoadingValue } from "./loadingSlice";
import { toast } from "react-toastify";
import { Status } from "../enums/statusEnum";
import { MultiLang } from "../enums/multiLangEnum";
import { APIResult } from "../interfaces/response.interface";
import { parseJwt } from "../helper/utilityHelper";

export const signIn = createAsyncThunk(
  "user/login",
  async (data: any, { rejectWithValue, dispatch }) => {
    const { Email, Password }: Login = data;
    try {
      apiHelper.setHeader({
        "Accept-Language": i18n.language === MultiLang.EN ? "en-US" : "ja-JP",
      });

      const response = await apiHelper
        .handleRequest({
          method: "POST",
          url: `api/Auth/SignIn`,
          data: { Email, Password },
        })
        .then((res) => res.data);
      return response;
    } catch (err: any) {
      dispatch(setLoadingValue({ isLoading: false }));
      let result = err.response.data as APIResult;
      toast.error(result.Message);
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const signUp = createAsyncThunk(
  "user/signUp",
  async (data: UserSignUpRequest, { rejectWithValue, dispatch }) => {
    try {
      apiHelper.setHeader({
        "Accept-Language": i18n.language === MultiLang.EN ? "en-US" : "ja-JP",
      });

      const response = await apiHelper
        .handleRequest({
          method: "POST",
          url: `api/Auth/SignUp`,
          data: data,
        })
        .then((res) => res.data);
      dispatch(resetData());
      dispatch(setLoadingValue({ isLoading: false }));
      if (response.StatusCode === Status.OK) {
        dispatch(
          setDefaultValue({
            ...response.Data,
          })
        );
      } else {
        toast.error(response.Message);
      }
      return true;
    } catch (err: any) {
      dispatch(setLoadingValue({ isLoading: false }));
      let result = err.response.data as APIResult;
      toast.error(result.Message);
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const signOut = createAsyncThunk(
  "user/signOut",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await apiHelper
        .handleRequest({
          method: "POST",
          url: `api/Auth/Invoke`,
          data: {},
        })
        .then((res) => res.data);
      dispatch(resetData());
      return response;
    } catch (err: any) {
      dispatch(setLoadingValue({ isLoading: false }));
      toast.error(err.message);
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

const initialState: InitialStateAuthentication<Authentication> = {
  entities: { Status: 0 },
  loading: "idle",
  saving: "idle",
  currentRequestId: null,
  error: null,
};

const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(signIn.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.currentRequestId = undefined;
          if (action.payload.token && action.payload.token !== "") {
            state.entities = {
              Status: 200,
            };
            localStorage.setItem(Key.RefreshToken, action.payload.refreshToken);
            localStorage.setItem(Key.Token, action.payload.token);
            let infoToken = parseJwt(action.payload.token);
            localStorage.setItem(Key.TimeExpired, infoToken.exp);
            localStorage.setItem(Key.TimeSession, infoToken.timeSession);
          } else {
            state.entities = {
              Status: 400,
            };
          }
        }
      })
      .addCase(signIn.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export default authenticationSlice.reducer;
