import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { isNull, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import PasswordStrengthBar from "react-password-strength-bar";

import {
  PasswordNotSame,
  FieldIsMandatory,
  SignUpSuccess,
  FieldIsNotValid,
} from "../../../noticeGlobal/loginNotice";
import { UserSignUpRequest } from "../../../interfaces/login.interface";
import { InitialReducer } from "../../../interfaces/reducer.interface";

import { signUp } from "../../../slices/authenticationSlice";
import { setDefaultValue } from "../../../slices/loadingSlice";

import { isValidEmail } from "../../../helper/utilityHelper";

import "./SignUp.scss";

interface ICallBackFunction {
  signUpCallBack: (isClick: boolean) => void;
}

const SignUp = ({ signUpCallBack }: ICallBackFunction) => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const userItem = useSelector((state: InitialReducer) => state.userItem);

  const buttonSubmitRef = useRef<HTMLButtonElement>(null);
  const fullNameRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const addressRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const rePasswordRef = useRef<HTMLInputElement>(null);

  const formik = useFormik<UserSignUpRequest>({
    initialValues: {
      FullName: "",
      Phone: "",
      GUID: "",
      Email: "",
      Address: "",
      Password: "",
      RePassword: "",
      Role: "User",
    },
    onSubmit: (values) => {
      let validate = handleValidateForm(values);

      if (!validate) {
        return;
      }

      if (values.Password !== values.RePassword) {
        toast.warning(t(PasswordNotSame));
        return;
      }
      dispatch(setDefaultValue({ isLoading: true }));
      dispatch(signUp(values));
    },
  });

  useEffect(() => {
    buttonSubmitRef.current?.focus();
  }, []);

  useEffect(() => {
    if (!isEmpty(userItem.guid)) {
      signUpCallBack(!isEmpty(userItem.guid));
      toast.success(t(SignUpSuccess));
    } else {
      signUpCallBack(!isEmpty(userItem.guid));
    }
  }, [dispatch, userItem]);

  const handleValidateForm = (values: UserSignUpRequest): boolean => {
    let isMissing = true;
    if (isNull(values.FullName) || isEmpty(values.FullName.trim())) {
      isMissing = false;
      toast.warning(`${t("lbFullName")} ${t(FieldIsMandatory)}`);
      if (fullNameRef.current) {
        fullNameRef.current.focus();
      }

      return isMissing;
    }

    if (isNull(values.Phone.trim()) || isEmpty(values.Phone.trim())) {
      isMissing = false;
      toast.warning(`${t("lbPhone")} ${t(FieldIsMandatory)}`);
      if (phoneRef.current) {
        phoneRef.current.focus();
      }

      return isMissing;
    }

    if (isNull(values.Email) || isEmpty(values.Email.trim())) {
      isMissing = false;
      toast.warning(`${t("lbEmail")} ${t(FieldIsMandatory)}`);
      if (phoneRef.current) {
        phoneRef.current.focus();
      }

      return isMissing;
    }

    if (!isValidEmail(values.Email)) {
      isMissing = false;
      toast.warning(`${t("lbEmail")} ${t(FieldIsNotValid)}`);
      if (emailRef.current) {
        emailRef.current.focus();
      }

      return isMissing;
    }

    if (isNull(values.Password) || isEmpty(values.Password.trim())) {
      isMissing = false;
      toast.warning(`${t("lbPassword")} ${t(FieldIsMandatory)}`);
      return isMissing;
    }

    if (isNull(values.RePassword) || isEmpty(values.RePassword.trim())) {
      isMissing = false;
      toast.warning(`${t("lbRePassword")} ${t(FieldIsMandatory)}`);
      return isMissing;
    }

    return isMissing;
  };

  return (
    <>
      <form className="form-sign-up" onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="form-sign-up-input">
            <label>
              {t("lbFullName")}
              <span className="mandatory">*</span>
            </label>
            <input
              ref={fullNameRef}
              name="FullName"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.FullName}
            />
          </div>
          <div className="form-sign-up-input">
            <label>
              {t("lbPhone")}
              <span className="mandatory">*</span>
            </label>
            <input
              ref={phoneRef}
              name="Phone"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.Phone}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-sign-up-input">
            <label>
              {t("lbEmail")}
              <span className="mandatory">*</span>
            </label>
            <input
              ref={emailRef}
              name="Email"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.Email}
            />
          </div>
          <div className="form-sign-up-input">
            <label> {t("lbAddress")}</label>
            <input
              ref={addressRef}
              name="Address"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.Address}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-sign-up-input">
            <label>
              {t("lbPassword")}
              <span className="mandatory">*</span>
            </label>
            <input
              ref={passwordRef}
              name="Password"
              type="password"
              onChange={formik.handleChange}
              value={formik.values.Password}
            />
            <PasswordStrengthBar
              password={formik.values.Password}
              scoreWords={[
                t("stsWeak"),
                t("stsWeak"),
                t("stsWellDone"),
                t("stsGood"),
                t("stsStrong"),
              ]}
              shortScoreWord={t("stsShort")}
            />
          </div>
          <div className="form-sign-up-input">
            <label>
              {t("lbRePassword")}
              <span className="mandatory">*</span>
            </label>
            <input
              ref={rePasswordRef}
              name="RePassword"
              type="password"
              onChange={formik.handleChange}
              value={formik.values.RePassword}
            />
          </div>
        </div>

        <button
          ref={buttonSubmitRef}
          className="btn-login mt-5 mb-15"
          type="submit"
          onKeyDown={(e: any) => e.event === "Enter" && formik.handleChange}
        >
          {t("btnSignUp")}
        </button>

        <small>
          {t("txtHintSignUp")}{" "}
          <a onClick={() => signUpCallBack(true)}>{t("btnSignIn")}</a>
        </small>
      </form>
    </>
  );
};

export default SignUp;
