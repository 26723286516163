import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { isUndefined } from "lodash";

import { fetchToken } from "../api/apiRefreshToken";
import i18n from "../i18n";
import apiHelper from "../api/apiHelper";

import { resetData, setDefaultValue } from "./linkUserDeviceItemSlice";
import { setDefaultValue as setLoadingValue } from "./loadingSlice";

import { LinkUserDeviceRequest } from "../interfaces/device.interface";
import { InitialState } from "../interfaces/reducer.interface";
import { APIResult } from "../interfaces/response.interface";

import { Key } from "../enums/localStorageEnum";
import { Status } from "../enums/statusEnum";
import { MultiLang } from "../enums/multiLangEnum";

export const postLinkUserDevice = createAsyncThunk(
  "linkDevice/postLinkUserDevice",
  async (data: LinkUserDeviceRequest, { rejectWithValue, dispatch }) => {
    try {
      await fetchToken();
      apiHelper.setHeader({
        Authorization: localStorage.getItem(Key.Token),
        "Accept-Language": i18n.language === MultiLang.EN ? "en-US" : "ja-JP",
      });

      const response = await apiHelper
        .handleRequest({
          method: "POST",
          url: "api/LinkUserDevice",
          data: data,
        })
        .then((res) => res.data);
      dispatch(setLoadingValue({ isLoading: false }));
      if (response.StatusCode === Status.OK) {
        dispatch(
          setDefaultValue({
            ...response.Data,
          })
        );
        toast.success(response.Message);
      } else {
        toast.error(response.Message);
      }
      return true;
    } catch (err: any) {
      dispatch(setLoadingValue({ isLoading: false }));
      let result = err.response.data as APIResult;
      if (isUndefined(result.Message)) {
        toast.error(result.title);
      } else {
        toast.error(result.Message);
      }
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const putLinkUserDevice = createAsyncThunk(
  "linkDevice/putDevice",
  async (data: LinkUserDeviceRequest, { rejectWithValue, dispatch }) => {
    try {
      await fetchToken();
      apiHelper.setHeader({
        Authorization: localStorage.getItem(Key.Token),
        "Accept-Language": i18n.language === MultiLang.EN ? "en-US" : "ja-JP",
      });

      const response = await apiHelper
        .handleRequest({
          method: "PUT",
          url: "api/LinkUserDevice",
          data: { GUID: data.deviceGUID, Name: data.name },
        })
        .then((res) => res.data);
      dispatch(resetData(response.Data));
      dispatch(setLoadingValue({ isLoading: false }));
      dispatch(setDefaultValue(response.Data));
      if (response.StatusCode === Status.OK) {
      } else {
        toast.error(response.Message);
      }
      return true;
    } catch (err: any) {
      dispatch(setLoadingValue({ isLoading: false }));
      let result = err.response.data as APIResult;
      if (isUndefined(result.Message)) {
        toast.error(result.title);
      } else {
        toast.error(result.Message);
      }
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState: InitialState<LinkUserDeviceRequest> = {
  entities: [],
  loading: "idle",
  saving: "idle",
  currentRequestId: null,
  error: null,
};

const linkDeviceSlice = createSlice({
  name: "linkDevices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export default linkDeviceSlice.reducer;
