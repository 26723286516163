import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { MultiSelect } from "react-multi-select-component";

import { LinkPolygonFailed } from "../../../noticeGlobal/loginNotice";

import "./Modal.scss";
import { useTranslation } from "react-i18next";

interface IModal<T> {
  isOpenModal: boolean;
  onCloseCallback: (isOpen: boolean) => void;
  onCallBackGetData: (safeZones: string[]) => void;
  listPolygon: Array<T>;
  listSelectedPolygon: Array<T>;
  nameKey: string;
  nameValue: string;
  nameTextBox: string;
}

function onGetDataSearch<T>(
  items: Array<T>,
  nameKey: string,
  nameValue: string
) {
  const result = new Array<{ value: string; label: string }>();
  items.forEach((s: any) => {
    result.push({ value: s[nameKey], label: s[nameValue] });
  });

  return result;
}

const Modal = <T,>({
  isOpenModal,
  onCloseCallback,
  onCallBackGetData,
  listPolygon,
  listSelectedPolygon,
  nameKey,
  nameValue,
  nameTextBox,
}: IModal<T>) => {
  const { t } = useTranslation();
  const [item, setItem] = useState(
    onGetDataSearch<T>(listSelectedPolygon, nameKey, nameValue)
  );
  const linkPolygonRef = useRef<HTMLInputElement>(null);

  const onHandleData = (items: Array<{ value: string; label: string }>) => {
    if (item.length == 0) {
      linkPolygonRef.current?.focus();
      toast.warning(t(LinkPolygonFailed));
      return;
    }

    const data = items.map((s) => {
      return s.value;
    });

    onCallBackGetData(data);
  };

  const customValueRenderer = (selected: any, _options: any) => {
    return selected.length
      ? selected.map(({ label }: any) => "✔️ " + label)
      : "";
  };

  return (
    <>
      <div
        id="myModal"
        className={`modal-polygon ${isOpenModal ? "block" : "none"}`}
      >
        <div className={`modal-polygon-content`}>
          <div className="modal-polygon-content-header">
            <span onClick={() => onCloseCallback(false)} className="close">
              &times;
            </span>
          </div>
          <div className="modal-polygon-content-body">
            <MultiSelect
              isOpen={true}
              options={onGetDataSearch(listPolygon, nameKey, nameValue)}
              value={item}
              onChange={setItem}
              valueRenderer={customValueRenderer}
              shouldToggleOnHover={false}
              ArrowRenderer={({ expanded }) => <></>}
              disableSearch={false}
              labelledBy="Select"
            />
          </div>
          <div className="modal-polygon-content-footer">
            <button
              onClick={() => {
                onHandleData(item);
                onCloseCallback(false);
              }}
            >
              {t(nameTextBox)}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
