import { configureStore } from "@reduxjs/toolkit";

import userSlice from "../slices/userSlice";
import authenticationSlice from "../slices/authenticationSlice";
import linkDeviceSlice from "../slices/linkUserDeviceSlice";
import userItemSlice from "../slices/userItemSlice";
import linkUserDeviceItemSlice from "../slices/linkUserDeviceItemSlice";
import loadingSlice from "../slices/loadingSlice";
import locationSlice from "../slices/locationSlice";
import safeZoneSlice from "../slices/safeZoneSlice";
import safeZoneItemSlice from "../slices/safeZoneItemSlice";
import safeZoneUserSlice from "../slices/safeZoneUserSlice";
import linkSafeZoneDeviceItemSlice from "../slices/linkSafeZoneDeviceItemSlice";
import deviceSlice from "../slices/deviceSlice";
import deviceItemSlice from "../slices/deviceItemSlice";

const store = configureStore({
  reducer: {
    user: userSlice,
    login: authenticationSlice,
    linkDevice: linkDeviceSlice,
    userItem: userItemSlice,
    linkUserDeviceItem: linkUserDeviceItemSlice,
    loader: loadingSlice,
    location: locationSlice,
    safeZone: safeZoneSlice,
    safeZoneItem: safeZoneItemSlice,
    safeZoneUser: safeZoneUserSlice,
    linkSafeZoneDeviceItem: linkSafeZoneDeviceItemSlice,
    device: deviceSlice,
    deviceItem: deviceItemSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
