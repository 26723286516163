import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { RiseLoader } from "react-spinners";
import { useSelector } from "react-redux";
import "./i18n";

import { useWindowSize } from "./customHook/customHooks";

import Login from "./pages/login/Login";
import Home from "./pages/home/Home";
import Devices from "./pages/device/Device";
import Polygon from "./pages/polygon/Polygon";
import Map from "./pages/map/Map";
import Profile from "./pages/profile/Profile";
import NotFound from "./pages/404NotFound/NotFound";

import { InitialReducer } from "./interfaces/reducer.interface";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";

const App = (): JSX.Element => {
  const loader = useSelector((state: InitialReducer) => state.loader);
  const [size, height] = useWindowSize();

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />}></Route>
        {/* Using for user site */}
        <Route path="/" element={<Home />}>
          <Route path="profile" element={<Profile />} />
          <Route path="tracking/:device" element={<Map />} />
          <Route path="devices" element={<Devices />} />
          <Route path="polygons" element={<Polygon />} />
        </Route>

        {/* Only match this when no other routes match */}
        <Route path="*" element={<NotFound />} />
      </Routes>

      <ToastContainer
        position={size >= 768 ? `bottom-right` : `top-center`}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      {loader.isLoading ? (
        <div className="loader">
          <RiseLoader
            color={"#eeb557"}
            loading={loader.isLoading}
            size={15}
            aria-label="Loading Spinner"
          />
        </div>
      ) : null}
    </>
  );
};

export default App;
