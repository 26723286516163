import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import env from "react-dotenv";

import i18n from "../i18n";
import apiHelper from "../api/apiHelper";
import { fetchToken } from "../api/apiRefreshToken";

import { Device, DeviceRequest } from "../interfaces/device.interface";
import { InitialState } from "../interfaces/reducer.interface";
import { Key } from "../enums/localStorageEnum";
import { APIResult } from "../interfaces/response.interface";

import { setDefaultValue as setLoadingValue } from "./loadingSlice";

import { Status } from "../enums/statusEnum";
import { MultiLang } from "../enums/multiLangEnum";
import { isUndefined } from "lodash";

export const fetchDevice = createAsyncThunk(
  "device/fetchDevice",
  async (data: DeviceRequest, { rejectWithValue, dispatch }) => {
    try {
      await fetchToken();
      apiHelper.setHeader({
        Authorization: localStorage.getItem(Key.Token),
        "Accept-Language": i18n.language === MultiLang.EN ? "en-US" : "ja-JP",
      });

      const response = await apiHelper
        .handleRequest({
          method: "GET",
          url: "api/Device",
          params: { PageNum: data.PageNum, PageSize: data.PageSize },
        })
        .then((res) => res.data);
      dispatch(setLoadingValue({ isLoading: false }));
      if (response.StatusCode === Status.OK) {
      } else {
        toast.error(response.Message);
      }
      return response;
    } catch (err: any) {
      dispatch(setLoadingValue({ isLoading: false }));
      let result = err.response.data as APIResult;
      if (isUndefined(result.Message)) {
        toast.error(result.title);
      } else {
        toast.error(result.Message);
      }
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState: InitialState<Device> = {
  entities: [],
  loading: "idle",
  saving: "idle",
  total: 0,
  pageNum: 0,
  pageSize: env.PAGE_SIZE,
  currentRequestId: null,
  error: null,
};

const deviceSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDevice.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchDevice.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          const { Data, PageNum, PageSize, Total }: APIResult = action.payload;
          state.loading = "idle";
          state.entities = Data ?? [];
          state.total = Total;
          state.pageNum = PageNum;
          state.pageSize = PageSize;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchDevice.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export default deviceSlice.reducer;
