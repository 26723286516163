import moment, { tz } from "moment";
import { unix } from "moment-timezone";
import env from "react-dotenv";

import apiHelper from "./apiHelper";
import { Key } from "../enums/localStorageEnum";
import { parseJwt } from "../helper/utilityHelper";

export const fetchToken = async (isImmediately: boolean = false) => {
  let dateOverdue = unix(parseInt(localStorage.getItem(Key.TimeExpired) || ""))
    .tz(env.REGION)
    .toDate();

  if (isImmediately) {
    const response = await apiHelper
      .handleRequest({
        method: "POST",
        url: `api/Auth/refreshAuthorize`,
        data: { RefreshToken: localStorage.getItem(Key.RefreshToken) },
      })
      .then((res) => res.data);

    localStorage.setItem(Key.Token, response);
    let infoToken = parseJwt(response);
    localStorage.setItem(Key.TimeExpired, infoToken.exp);

    return;
  }

  if (dateOverdue < tz(env.REGION).toDate()) {
    const response = await apiHelper
      .handleRequest({
        method: "POST",
        url: `api/Auth/refreshAuthorize`,
        data: { RefreshToken: localStorage.getItem(Key.RefreshToken) },
      })
      .then((res) => res.data);

    localStorage.setItem(Key.Token, response);
    let infoToken = parseJwt(response);
    localStorage.setItem(Key.TimeExpired, infoToken.exp);

    return;
  }
};
