import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isNull, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import moment, { tz } from "moment";
import env from "react-dotenv";

import logo from "../../static/logo.png";
import "./Login.scss";
import "react-toastify/dist/ReactToastify.css";

import { InitialReducer } from "../../interfaces/reducer.interface";
import { signIn } from "../../slices/authenticationSlice";

import {
  LoginSuccess,
  LoginFailed,
  FieldIsMandatory,
} from "../../noticeGlobal/loginNotice";

import { Status } from "../../enums/statusEnum";
import { Key } from "../../enums/localStorageEnum";

import { resetData } from "../../slices/userItemSlice";
import { setDefaultValue } from "../../slices/loadingSlice";

import SignUp from "./signUp/SignUp";
import MultiLang from "../../components/multiLang/MultiLang";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const login = useSelector((state: InitialReducer) => state.login);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);

  useEffect(() => {
    if (localStorage.getItem(Key.Token)) {
      if (
        moment(localStorage.getItem(Key.TimeSession)).tz(env.REGION).toDate() >=
        tz(env.REGION).toDate()
      ) {
        navigate("/");
      } else {
        localStorage.setItem(Key.Token, "");
        localStorage.setItem(Key.TimeExpired, "");
      }
    }
  }, []);

  useEffect(() => {
    var token = localStorage.getItem(Key.Token);
    if (token && token !== "") {
      if (login.entities.Status === Status.OK) {
        dispatch(setDefaultValue({ isLoading: false }));
        toast.success(t(LoginSuccess));
        setTimeout(() => {
          navigate("/");
        }, 800);
      } else if (login.entities.Status === Status.Failed) {
        toast.error(t(LoginFailed));
      }
    }
  }, [dispatch, login.entities]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (isNull(email) || isEmpty(email)) {
      toast.warning(`${t("lbEmail")} ${t(FieldIsMandatory)}`);
      return;
    }

    if (isNull(password) || isEmpty(password.trim())) {
      toast.warning(`${t("lbPassword")} ${t(FieldIsMandatory)}`);
      return;
    }

    dispatch(setDefaultValue({ isLoading: true }));
    dispatch(signIn({ Email: email, Password: password }));
  };

  const handleSignUp = (isBool: boolean) => {
    dispatch(setDefaultValue({ isLoading: false }));
    setIsSignUp(!isBool);
  };

  return (
    <div className="form-login">
      <div className={`form-login-card ${isSignUp ? "w-30" : ""}`}>
        <img src={logo} width="90" height="100" />
        {!isSignUp ? (
          <form
            className="form-login-card-sign-in"
            onSubmit={(e) => handleSubmit(e)}
          >
            <div className="form-login-card-input">
              <input
                className={email ? "has-value" : ""}
                name="email"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label>{t("lbEmail")}</label>
            </div>
            <div className="form-login-card-input">
              <input
                className={password ? "has-value" : ""}
                name="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label>{t("lbPassword")}</label>
            </div>
            <button className="btn-login mb-15" type="submit">
              {t("btnSignIn")}
            </button>
            <small>
              {t("txtHintSignIn")}{" "}
              <a
                onClick={() => {
                  setIsSignUp(!isSignUp);
                  dispatch(resetData());
                }}
              >
                {t("btnSignUp")}
              </a>
            </small>
          </form>
        ) : (
          <SignUp signUpCallBack={handleSignUp} />
        )}
        <MultiLang />
      </div>
    </div>
  );
};

export default Login;
