import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SetItemPayload {
  name: string;
  value: any;
}

const initialState: any = {
  polygon: [],
};
export const safeZoneItemSlice = createSlice({
  name: "safeZoneItem",
  initialState,
  reducers: {
    setItem: (state, action: PayloadAction<SetItemPayload>) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    setDefaultValue: (_, action) => {
      if (!action?.payload) return initialState;
      return { ...action.payload };
    },
    resetData: () => {
      return initialState;
    },
  },
});
export const { setItem, setDefaultValue, resetData } =
  safeZoneItemSlice.actions;

export default safeZoneItemSlice.reducer;
